/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: cart/model.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace cart.v1 {
    export class Cart extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            items?: CartItem[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("items" in data && data.items != undefined) {
                    this.items = data.items;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get items() {
            return pb_1.Message.getRepeatedWrapperField(this, CartItem, 2) as CartItem[];
        }
        set items(value: CartItem[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            id?: string;
            items?: ReturnType<typeof CartItem.prototype.toObject>[];
        }): Cart {
            const message = new Cart({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.items != null) {
                message.items = data.items.map(item => CartItem.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                items?: ReturnType<typeof CartItem.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.items != null) {
                data.items = this.items.map((item: CartItem) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.items.length)
                writer.writeRepeatedMessage(2, this.items, (item: CartItem) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Cart {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Cart();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.items, () => pb_1.Message.addToRepeatedWrapperField(message, 2, CartItem.deserialize(reader), CartItem));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Cart {
            return Cart.deserialize(bytes);
        }
    }
    export class CartItem extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
            sku?: string;
            quantity?: number;
            promotionId?: string;
            promotionRequired?: boolean;
            promotionMin?: number;
            promotionMax?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("sku" in data && data.sku != undefined) {
                    this.sku = data.sku;
                }
                if ("quantity" in data && data.quantity != undefined) {
                    this.quantity = data.quantity;
                }
                if ("promotionId" in data && data.promotionId != undefined) {
                    this.promotionId = data.promotionId;
                }
                if ("promotionRequired" in data && data.promotionRequired != undefined) {
                    this.promotionRequired = data.promotionRequired;
                }
                if ("promotionMin" in data && data.promotionMin != undefined) {
                    this.promotionMin = data.promotionMin;
                }
                if ("promotionMax" in data && data.promotionMax != undefined) {
                    this.promotionMax = data.promotionMax;
                }
            }
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get sku() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set sku(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get quantity() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set quantity(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get promotionId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set promotionId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get promotionRequired() {
            return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
        }
        set promotionRequired(value: boolean) {
            pb_1.Message.setField(this, 5, value);
        }
        get promotionMin() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set promotionMin(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get promotionMax() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set promotionMax(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            productId?: string;
            sku?: string;
            quantity?: number;
            promotionId?: string;
            promotionRequired?: boolean;
            promotionMin?: number;
            promotionMax?: number;
        }): CartItem {
            const message = new CartItem({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (data.sku != null) {
                message.sku = data.sku;
            }
            if (data.quantity != null) {
                message.quantity = data.quantity;
            }
            if (data.promotionId != null) {
                message.promotionId = data.promotionId;
            }
            if (data.promotionRequired != null) {
                message.promotionRequired = data.promotionRequired;
            }
            if (data.promotionMin != null) {
                message.promotionMin = data.promotionMin;
            }
            if (data.promotionMax != null) {
                message.promotionMax = data.promotionMax;
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
                sku?: string;
                quantity?: number;
                promotionId?: string;
                promotionRequired?: boolean;
                promotionMin?: number;
                promotionMax?: number;
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.sku != null) {
                data.sku = this.sku;
            }
            if (this.quantity != null) {
                data.quantity = this.quantity;
            }
            if (this.promotionId != null) {
                data.promotionId = this.promotionId;
            }
            if (this.promotionRequired != null) {
                data.promotionRequired = this.promotionRequired;
            }
            if (this.promotionMin != null) {
                data.promotionMin = this.promotionMin;
            }
            if (this.promotionMax != null) {
                data.promotionMax = this.promotionMax;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            if (this.sku.length)
                writer.writeString(2, this.sku);
            if (this.quantity != 0)
                writer.writeInt32(3, this.quantity);
            if (this.promotionId.length)
                writer.writeString(4, this.promotionId);
            if (this.promotionRequired != false)
                writer.writeBool(5, this.promotionRequired);
            if (this.promotionMin != 0)
                writer.writeInt32(6, this.promotionMin);
            if (this.promotionMax != 0)
                writer.writeInt32(7, this.promotionMax);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CartItem {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CartItem();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    case 2:
                        message.sku = reader.readString();
                        break;
                    case 3:
                        message.quantity = reader.readInt32();
                        break;
                    case 4:
                        message.promotionId = reader.readString();
                        break;
                    case 5:
                        message.promotionRequired = reader.readBool();
                        break;
                    case 6:
                        message.promotionMin = reader.readInt32();
                        break;
                    case 7:
                        message.promotionMax = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CartItem {
            return CartItem.deserialize(bytes);
        }
    }
}
