import * as Api from "../proto/promotion/service";
import { AuthGrpcInterceptor } from "./grpc-interceptor.service";
import { endpointBaseGRPC } from "src/main";
import { Injectable } from "@angular/core";
import { PromotionParams } from "../models/promotion";

@Injectable({
  providedIn: "root",
})
export class GrpcPromotionService {
  private client: Api.promotion.v1.PromotionServiceClient = new Api.promotion.v1.PromotionServiceClient(endpointBaseGRPC, undefined, {
    unaryInterceptors: [new AuthGrpcInterceptor()],
  });

  constructor() {}

  getPromotions(params: PromotionParams = {}) {
    console.log("Params pra buscar promocoes ==> ", params);
    const request = new Api.promotion.v1.SearchPromotionsRequest(params);
    return this.client.SearchPromotions(request, {});
  }

  getPromotionById(promotionId: string) {
    const request = new Api.promotion.v1.GetPromotionByIDRequest({ promotionId });
    return this.client.GetPromotionByID(request, {});
  }

  getPromotionFlavors() {
    const request = new Api.promotion.v1.GetSearchFilterPromotionsRequest();
    return this.client.GetSearchFilterPromotions(request, {});
  }
}
