import { BaseComponent } from "../base/base.component";
import { CardSelectComponent } from "../modals/card-select/card-select.component";
import { Cart } from "src/app/models/cart";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { IonAccordionGroup } from "@ionic/angular";
import { Payment, PaymentCondition } from "src/app/models/payment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-payment-methods",
  templateUrl: "./payment-methods.component.html",
  styleUrls: ["./payment-methods.component.scss"],
})
export class PaymentMethodsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild("accordionGroup", { static: true }) accordionGroup: IonAccordionGroup;

  @Input() paymentConditions?: PaymentCondition;
  @Input() loading = false;
  @Input() disabledAccordion = false;
  @Input() debts = false;
  @Output() update = new EventEmitter();
  @Output() selectPayment = new EventEmitter();

  selectedPayment?: Payment;
  selectedCard?: any;

  subscription?: Subscription;

  cartData: Cart;

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (!this.paymentConditions && !this.debts) {
      this.subscription = this.cart.updateCart$.subscribe((cart) => {
        this.cartData = cart;
        if (this.cartData.isEditing) {
          this.disabledAccordion = true;
        } else {
          this.disabledAccordion = false;
        }
        this.handlePaymentConditions(cart);
        this.ref.detectChanges();
      });
    }
  }

  changePaymentGroup(ev: any) {
    if (!this.paymentConditions) {
      return;
    }
    const selectedValue = ev.detail.value;

    if (!selectedValue) {
      return;
    }
    if (selectedValue === "credit") {
      this.openCreditModal();
    } else if (this.paymentConditions[selectedValue] && Array.isArray(this.paymentConditions[selectedValue])) {
      this.selectedPayment = this.paymentConditions[selectedValue][0];
    } else {
      this.selectedPayment = this.paymentConditions[selectedValue];
    }
    this.ref.detectChanges();
    if (this.selectedPayment?.paymentMethod === "pix") {
      this.selectPayment.emit({ payment: this.selectedPayment });
    } else if (this.selectedPayment?.paymentMethod === "employees") {
      this.selectPayment.emit({ payment: this.selectedPayment });
    } else if (this.selectedPayment?.paymentMethod === "bankSlip") {
      this.selectPayment.emit({ payment: this.paymentConditions[selectedValue][0] });
    } else if (this.selectedPayment?.paymentMethod === "credit") {
      console.info("Credit card not emit immediately");
    } else {
      console.error("Forma de pagamento não foi cadastrada", this.selectedPayment);
    }
  }

  openModalCard() {
    if (!this.cartData.isEditing && this.disabledAccordion && this.paymentConditions?.["credit"]) {
      this.openCreditModal();
    }
    //  else if (this.cartData.isEditing) {
    //   this.system.showAlert("warn", "Atenção", "Você não pode alterar a forma de pagamento enquanto estiver editando o carrinho.", "Ok");
    //   return;
    // }
  }

  handlePaymentConditions(cart: Cart) {
    const conditions = cart.paymentMethods;
    if (!conditions) {
      return;
    }
    this.paymentConditions = undefined;
    if (Array.isArray(conditions?.["pix"])) {
      conditions["pix"] = conditions["pix"][0];
    }
    if (Array.isArray(conditions?.["employees"])) {
      conditions["employees"] = conditions["employees"][0];
    }
    //Garantir que bankSlip é sempre um array
    if (conditions["bankSlip"] && !Array.isArray(conditions["bankSlip"])) {
      conditions["bankSlip"] = [conditions["bankSlip"]];
    }
    //Garantir que cartão de crédito é sempre um array
    if (conditions["credit"] && !Array.isArray(conditions["credit"])) {
      conditions["credit"] = [conditions["credit"]];
    }
    this.fixLabels(conditions);
    console.log("Condições possíveis: ", conditions);
    this.handleSelectedPayment(conditions);
  }

  fixLabels(conditions) {
    if (conditions?.["pix"]) {
      conditions["pix"].paymentMethodDescription = "PIX";
    }
  }

  handleSelectedPayment(conditions) {
    if (!conditions) {
      return;
    }
    let paymentStringActive = "";
    this.selectedPayment = undefined;
    //Verificando se PIX é pagamento ativo
    if (conditions?.["pix"]?.active) {
      this.selectedPayment = conditions["pix"];
      paymentStringActive = "pix";
      this.selectPayment.emit({ payment: this.selectedPayment, notUpdateCart: true });
    }
    //Verificando se Funcionário é pagamento ativo
    else if (conditions?.["employees"]?.active) {
      this.selectedPayment = conditions["employees"];
      paymentStringActive = "employees";
      this.selectPayment.emit({ payment: this.selectedPayment, notUpdateCart: true });
    }
    //Verificando se crédito tem pagamento ativo
    else if (conditions["credit"] && !this.selectedPayment && conditions["credit"].some((c) => c.active)) {
      this.selectedPayment = conditions["credit"].find((c) => c.active);
      paymentStringActive = "credit";
      this.selectPayment.emit({ payment: this.selectedPayment, notUpdateCart: true });
    }
    //Verificando se Boleto tem pagamento ativo
    else if (conditions["bankSlip"] && !this.selectedPayment && conditions["bankSlip"].some((b) => b.active)) {
      console.log("Boleto tem pagamento ativo: ", conditions["bankSlip"]);
      this.selectedPayment = conditions["bankSlip"].find((b) => b.active);
      paymentStringActive = "bankSlip";
      this.selectPayment.emit({ payment: this.selectedPayment, notUpdateCart: true });
    }
    if (this.cartData.isEditing) {
      let value;
      //Forma de pagamento que se espera um object
      if (paymentStringActive === "pix" || paymentStringActive === "employees") {
        value = this.selectedPayment;
      } else {
        value = Array.isArray(this.selectedPayment) ? this.selectedPayment : [this.selectedPayment];
      }
      this.paymentConditions = {
        [paymentStringActive]: value,
      };
    } else {
      this.paymentConditions = {
        ...conditions,
      };
    }
    console.log("Payment conditions ========> ", this.paymentConditions);
  }

  toggleAccordion = () => {
    const nativeEl = this.accordionGroup;
    if (!this.selectedPayment) {
      console.error("Deveria ter pagamento selecionado!");
      return;
    }
    if (!nativeEl) {
      console.error("Deveria ter accordion element!");
      return;
    }
    if (nativeEl && nativeEl.value === this.selectedPayment.paymentMethod) {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = this.selectedPayment.paymentMethod;
    }
  };

  scrollToTop() {
    const el = document.getElementById("top-accordion");
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  clickPayment(payment: Payment) {
    this.selectedPayment = payment;
    this.selectPayment.emit({ payment: this.selectedPayment });
    this.toggleAccordion();
  }

  async openCreditModal() {
    //Deve-se desmarcar pagamentos ao abrir modal de crédito
    this.selectPayment.emit({ payment: null, notUpdateCart: true });
    const modal = await this.system.modalCtrl.create({
      component: CardSelectComponent,
      backdropDismiss: false,
    });
    modal.present();
    modal.onDidDismiss().then((res) => {
      if (res?.data?.card) {
        this.selectedCard = res.data.card;
        if (!this.disabledAccordion) {
          this.loading = true;
        }
        //Na seleção de cartão, definindo parcela 1x como default
        this.selectedPayment = this.paymentConditions?.["credit"]?.[0];
        this.update.emit({
          card: this.selectedCard,
          payment: this.selectedPayment,
        });
      } else {
        this.toggleAccordion();
      }
    });
  }
}
