/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: promotion/service.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../google/protobuf/empty";
import * as dependency_2 from "./../google/api/annotations";
import * as dependency_3 from "./model";
import * as dependency_4 from "./../cart/model";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace promotion.v1 {
    export class SavePromotionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            promotions?: dependency_3.promotion.v1.Promotion[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("promotions" in data && data.promotions != undefined) {
                    this.promotions = data.promotions;
                }
            }
        }
        get promotions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_3.promotion.v1.Promotion, 1) as dependency_3.promotion.v1.Promotion[];
        }
        set promotions(value: dependency_3.promotion.v1.Promotion[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            promotions?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>[];
        }): SavePromotionRequest {
            const message = new SavePromotionRequest({});
            if (data.promotions != null) {
                message.promotions = data.promotions.map(item => dependency_3.promotion.v1.Promotion.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                promotions?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>[];
            } = {};
            if (this.promotions != null) {
                data.promotions = this.promotions.map((item: dependency_3.promotion.v1.Promotion) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.promotions.length)
                writer.writeRepeatedMessage(1, this.promotions, (item: dependency_3.promotion.v1.Promotion) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SavePromotionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SavePromotionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.promotions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_3.promotion.v1.Promotion.deserialize(reader), dependency_3.promotion.v1.Promotion));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SavePromotionRequest {
            return SavePromotionRequest.deserialize(bytes);
        }
    }
    export class SavePromotionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): SavePromotionResponse {
            const message = new SavePromotionResponse({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SavePromotionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SavePromotionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SavePromotionResponse {
            return SavePromotionResponse.deserialize(bytes);
        }
    }
    export class GetSearchFilterPromotionsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetSearchFilterPromotionsRequest {
            const message = new GetSearchFilterPromotionsRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSearchFilterPromotionsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetSearchFilterPromotionsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetSearchFilterPromotionsRequest {
            return GetSearchFilterPromotionsRequest.deserialize(bytes);
        }
    }
    export class GetSearchFilterPromotionsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            flavors?: dependency_3.promotion.v1.Flavor[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("flavors" in data && data.flavors != undefined) {
                    this.flavors = data.flavors;
                }
            }
        }
        get flavors() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_3.promotion.v1.Flavor, 1) as dependency_3.promotion.v1.Flavor[];
        }
        set flavors(value: dependency_3.promotion.v1.Flavor[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            flavors?: ReturnType<typeof dependency_3.promotion.v1.Flavor.prototype.toObject>[];
        }): GetSearchFilterPromotionsResponse {
            const message = new GetSearchFilterPromotionsResponse({});
            if (data.flavors != null) {
                message.flavors = data.flavors.map(item => dependency_3.promotion.v1.Flavor.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                flavors?: ReturnType<typeof dependency_3.promotion.v1.Flavor.prototype.toObject>[];
            } = {};
            if (this.flavors != null) {
                data.flavors = this.flavors.map((item: dependency_3.promotion.v1.Flavor) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.flavors.length)
                writer.writeRepeatedMessage(1, this.flavors, (item: dependency_3.promotion.v1.Flavor) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSearchFilterPromotionsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetSearchFilterPromotionsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.flavors, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_3.promotion.v1.Flavor.deserialize(reader), dependency_3.promotion.v1.Flavor));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetSearchFilterPromotionsResponse {
            return GetSearchFilterPromotionsResponse.deserialize(bytes);
        }
    }
    export class GetPromotionsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetPromotionsRequest {
            const message = new GetPromotionsRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetPromotionsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetPromotionsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetPromotionsRequest {
            return GetPromotionsRequest.deserialize(bytes);
        }
    }
    export class GetPromotionsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            promotions?: dependency_3.promotion.v1.Promotion[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("promotions" in data && data.promotions != undefined) {
                    this.promotions = data.promotions;
                }
            }
        }
        get promotions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_3.promotion.v1.Promotion, 1) as dependency_3.promotion.v1.Promotion[];
        }
        set promotions(value: dependency_3.promotion.v1.Promotion[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            promotions?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>[];
        }): GetPromotionsResponse {
            const message = new GetPromotionsResponse({});
            if (data.promotions != null) {
                message.promotions = data.promotions.map(item => dependency_3.promotion.v1.Promotion.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                promotions?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>[];
            } = {};
            if (this.promotions != null) {
                data.promotions = this.promotions.map((item: dependency_3.promotion.v1.Promotion) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.promotions.length)
                writer.writeRepeatedMessage(1, this.promotions, (item: dependency_3.promotion.v1.Promotion) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetPromotionsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetPromotionsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.promotions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_3.promotion.v1.Promotion.deserialize(reader), dependency_3.promotion.v1.Promotion));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetPromotionsResponse {
            return GetPromotionsResponse.deserialize(bytes);
        }
    }
    export class GetPromotionByIDRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            promotionId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("promotionId" in data && data.promotionId != undefined) {
                    this.promotionId = data.promotionId;
                }
            }
        }
        get promotionId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set promotionId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            promotionId?: string;
        }): GetPromotionByIDRequest {
            const message = new GetPromotionByIDRequest({});
            if (data.promotionId != null) {
                message.promotionId = data.promotionId;
            }
            return message;
        }
        toObject() {
            const data: {
                promotionId?: string;
            } = {};
            if (this.promotionId != null) {
                data.promotionId = this.promotionId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.promotionId.length)
                writer.writeString(1, this.promotionId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetPromotionByIDRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetPromotionByIDRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.promotionId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetPromotionByIDRequest {
            return GetPromotionByIDRequest.deserialize(bytes);
        }
    }
    export class HasPromotionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sku?: string;
            products?: dependency_4.cart.v1.CartItem[];
            promotionId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sku" in data && data.sku != undefined) {
                    this.sku = data.sku;
                }
                if ("products" in data && data.products != undefined) {
                    this.products = data.products;
                }
                if ("promotionId" in data && data.promotionId != undefined) {
                    this.promotionId = data.promotionId;
                }
            }
        }
        get sku() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sku(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get products() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_4.cart.v1.CartItem, 2) as dependency_4.cart.v1.CartItem[];
        }
        set products(value: dependency_4.cart.v1.CartItem[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        get promotionId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set promotionId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            sku?: string;
            products?: ReturnType<typeof dependency_4.cart.v1.CartItem.prototype.toObject>[];
            promotionId?: string;
        }): HasPromotionRequest {
            const message = new HasPromotionRequest({});
            if (data.sku != null) {
                message.sku = data.sku;
            }
            if (data.products != null) {
                message.products = data.products.map(item => dependency_4.cart.v1.CartItem.fromObject(item));
            }
            if (data.promotionId != null) {
                message.promotionId = data.promotionId;
            }
            return message;
        }
        toObject() {
            const data: {
                sku?: string;
                products?: ReturnType<typeof dependency_4.cart.v1.CartItem.prototype.toObject>[];
                promotionId?: string;
            } = {};
            if (this.sku != null) {
                data.sku = this.sku;
            }
            if (this.products != null) {
                data.products = this.products.map((item: dependency_4.cart.v1.CartItem) => item.toObject());
            }
            if (this.promotionId != null) {
                data.promotionId = this.promotionId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sku.length)
                writer.writeString(1, this.sku);
            if (this.products.length)
                writer.writeRepeatedMessage(2, this.products, (item: dependency_4.cart.v1.CartItem) => item.serialize(writer));
            if (this.promotionId.length)
                writer.writeString(3, this.promotionId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HasPromotionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HasPromotionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sku = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.products, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_4.cart.v1.CartItem.deserialize(reader), dependency_4.cart.v1.CartItem));
                        break;
                    case 3:
                        message.promotionId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): HasPromotionRequest {
            return HasPromotionRequest.deserialize(bytes);
        }
    }
    export class HasPromotionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            promotion?: dependency_3.promotion.v1.Promotion;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("promotion" in data && data.promotion != undefined) {
                    this.promotion = data.promotion;
                }
            }
        }
        get promotion() {
            return pb_1.Message.getWrapperField(this, dependency_3.promotion.v1.Promotion, 1) as dependency_3.promotion.v1.Promotion;
        }
        set promotion(value: dependency_3.promotion.v1.Promotion) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasPromotion() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            promotion?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>;
        }): HasPromotionResponse {
            const message = new HasPromotionResponse({});
            if (data.promotion != null) {
                message.promotion = dependency_3.promotion.v1.Promotion.fromObject(data.promotion);
            }
            return message;
        }
        toObject() {
            const data: {
                promotion?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>;
            } = {};
            if (this.promotion != null) {
                data.promotion = this.promotion.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasPromotion)
                writer.writeMessage(1, this.promotion, () => this.promotion.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HasPromotionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HasPromotionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.promotion, () => message.promotion = dependency_3.promotion.v1.Promotion.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): HasPromotionResponse {
            return HasPromotionResponse.deserialize(bytes);
        }
    }
    export class SearchPromotionsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sku?: string;
            term?: string;
            flavorIds?: string[];
            sortByPosition?: boolean;
            paymentConditionCode?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sku" in data && data.sku != undefined) {
                    this.sku = data.sku;
                }
                if ("term" in data && data.term != undefined) {
                    this.term = data.term;
                }
                if ("flavorIds" in data && data.flavorIds != undefined) {
                    this.flavorIds = data.flavorIds;
                }
                if ("sortByPosition" in data && data.sortByPosition != undefined) {
                    this.sortByPosition = data.sortByPosition;
                }
                if ("paymentConditionCode" in data && data.paymentConditionCode != undefined) {
                    this.paymentConditionCode = data.paymentConditionCode;
                }
            }
        }
        get sku() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sku(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get term() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set term(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get flavorIds() {
            return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
        }
        set flavorIds(value: string[]) {
            pb_1.Message.setField(this, 3, value);
        }
        get sortByPosition() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set sortByPosition(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        get paymentConditionCode() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set paymentConditionCode(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            sku?: string;
            term?: string;
            flavorIds?: string[];
            sortByPosition?: boolean;
            paymentConditionCode?: string;
        }): SearchPromotionsRequest {
            const message = new SearchPromotionsRequest({});
            if (data.sku != null) {
                message.sku = data.sku;
            }
            if (data.term != null) {
                message.term = data.term;
            }
            if (data.flavorIds != null) {
                message.flavorIds = data.flavorIds;
            }
            if (data.sortByPosition != null) {
                message.sortByPosition = data.sortByPosition;
            }
            if (data.paymentConditionCode != null) {
                message.paymentConditionCode = data.paymentConditionCode;
            }
            return message;
        }
        toObject() {
            const data: {
                sku?: string;
                term?: string;
                flavorIds?: string[];
                sortByPosition?: boolean;
                paymentConditionCode?: string;
            } = {};
            if (this.sku != null) {
                data.sku = this.sku;
            }
            if (this.term != null) {
                data.term = this.term;
            }
            if (this.flavorIds != null) {
                data.flavorIds = this.flavorIds;
            }
            if (this.sortByPosition != null) {
                data.sortByPosition = this.sortByPosition;
            }
            if (this.paymentConditionCode != null) {
                data.paymentConditionCode = this.paymentConditionCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sku.length)
                writer.writeString(1, this.sku);
            if (this.term.length)
                writer.writeString(2, this.term);
            if (this.flavorIds.length)
                writer.writeRepeatedString(3, this.flavorIds);
            if (this.sortByPosition != false)
                writer.writeBool(4, this.sortByPosition);
            if (this.paymentConditionCode.length)
                writer.writeString(5, this.paymentConditionCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchPromotionsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchPromotionsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sku = reader.readString();
                        break;
                    case 2:
                        message.term = reader.readString();
                        break;
                    case 3:
                        pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                        break;
                    case 4:
                        message.sortByPosition = reader.readBool();
                        break;
                    case 5:
                        message.paymentConditionCode = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SearchPromotionsRequest {
            return SearchPromotionsRequest.deserialize(bytes);
        }
    }
    export class SearchPromotionsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            promotions?: dependency_3.promotion.v1.Promotion[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("promotions" in data && data.promotions != undefined) {
                    this.promotions = data.promotions;
                }
            }
        }
        get promotions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_3.promotion.v1.Promotion, 1) as dependency_3.promotion.v1.Promotion[];
        }
        set promotions(value: dependency_3.promotion.v1.Promotion[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            promotions?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>[];
        }): SearchPromotionsResponse {
            const message = new SearchPromotionsResponse({});
            if (data.promotions != null) {
                message.promotions = data.promotions.map(item => dependency_3.promotion.v1.Promotion.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                promotions?: ReturnType<typeof dependency_3.promotion.v1.Promotion.prototype.toObject>[];
            } = {};
            if (this.promotions != null) {
                data.promotions = this.promotions.map((item: dependency_3.promotion.v1.Promotion) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.promotions.length)
                writer.writeRepeatedMessage(1, this.promotions, (item: dependency_3.promotion.v1.Promotion) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchPromotionsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchPromotionsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.promotions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_3.promotion.v1.Promotion.deserialize(reader), dependency_3.promotion.v1.Promotion));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SearchPromotionsResponse {
            return SearchPromotionsResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedPromotionServiceService {
        static definition = {
            GetPromotions: {
                path: "/promotion.v1.PromotionService/GetPromotions",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetPromotionsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetPromotionsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetPromotionsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetPromotionsResponse.deserialize(new Uint8Array(bytes))
            },
            GetPromotionByID: {
                path: "/promotion.v1.PromotionService/GetPromotionByID",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetPromotionByIDRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetPromotionByIDRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: dependency_3.promotion.v1.Promotion) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => dependency_3.promotion.v1.Promotion.deserialize(new Uint8Array(bytes))
            },
            UpdatePromotion: {
                path: "/promotion.v1.PromotionService/UpdatePromotion",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: dependency_3.promotion.v1.Promotion) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => dependency_3.promotion.v1.Promotion.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: dependency_1.google.protobuf.Empty) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => dependency_1.google.protobuf.Empty.deserialize(new Uint8Array(bytes))
            },
            HasPromotion: {
                path: "/promotion.v1.PromotionService/HasPromotion",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: HasPromotionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => HasPromotionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: HasPromotionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => HasPromotionResponse.deserialize(new Uint8Array(bytes))
            },
            SearchPromotions: {
                path: "/promotion.v1.PromotionService/SearchPromotions",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: SearchPromotionsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => SearchPromotionsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: SearchPromotionsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => SearchPromotionsResponse.deserialize(new Uint8Array(bytes))
            },
            GetSearchFilterPromotions: {
                path: "/promotion.v1.PromotionService/GetSearchFilterPromotions",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetSearchFilterPromotionsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetSearchFilterPromotionsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetSearchFilterPromotionsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetSearchFilterPromotionsResponse.deserialize(new Uint8Array(bytes))
            },
            SavePromotion: {
                path: "/promotion.v1.PromotionService/SavePromotion",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: SavePromotionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => SavePromotionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: SavePromotionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => SavePromotionResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract GetPromotions(call: grpc_1.ServerUnaryCall<GetPromotionsRequest, GetPromotionsResponse>, callback: grpc_1.sendUnaryData<GetPromotionsResponse>): void;
        abstract GetPromotionByID(call: grpc_1.ServerUnaryCall<GetPromotionByIDRequest, dependency_3.promotion.v1.Promotion>, callback: grpc_1.sendUnaryData<dependency_3.promotion.v1.Promotion>): void;
        abstract UpdatePromotion(call: grpc_1.ServerUnaryCall<dependency_3.promotion.v1.Promotion, dependency_1.google.protobuf.Empty>, callback: grpc_1.sendUnaryData<dependency_1.google.protobuf.Empty>): void;
        abstract HasPromotion(call: grpc_1.ServerUnaryCall<HasPromotionRequest, HasPromotionResponse>, callback: grpc_1.sendUnaryData<HasPromotionResponse>): void;
        abstract SearchPromotions(call: grpc_1.ServerUnaryCall<SearchPromotionsRequest, SearchPromotionsResponse>, callback: grpc_1.sendUnaryData<SearchPromotionsResponse>): void;
        abstract GetSearchFilterPromotions(call: grpc_1.ServerUnaryCall<GetSearchFilterPromotionsRequest, GetSearchFilterPromotionsResponse>, callback: grpc_1.sendUnaryData<GetSearchFilterPromotionsResponse>): void;
        abstract SavePromotion(call: grpc_1.ServerUnaryCall<SavePromotionRequest, SavePromotionResponse>, callback: grpc_1.sendUnaryData<SavePromotionResponse>): void;
    }
    export class PromotionServiceClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static GetPromotions = new grpc_web_1.MethodDescriptor<GetPromotionsRequest, GetPromotionsResponse>("/promotion.v1.PromotionService/GetPromotions", grpc_web_1.MethodType.UNARY, GetPromotionsRequest, GetPromotionsResponse, (message: GetPromotionsRequest) => message.serialize(), GetPromotionsResponse.deserialize);
        GetPromotions(message: GetPromotionsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetPromotionsRequest, GetPromotionsResponse>(this._address + "/promotion.v1.PromotionService/GetPromotions", message, metadata || {}, PromotionServiceClient.GetPromotions);
        }
        private static GetPromotionByID = new grpc_web_1.MethodDescriptor<GetPromotionByIDRequest, dependency_3.promotion.v1.Promotion>("/promotion.v1.PromotionService/GetPromotionByID", grpc_web_1.MethodType.UNARY, GetPromotionByIDRequest, dependency_3.promotion.v1.Promotion, (message: GetPromotionByIDRequest) => message.serialize(), dependency_3.promotion.v1.Promotion.deserialize);
        GetPromotionByID(message: GetPromotionByIDRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetPromotionByIDRequest, dependency_3.promotion.v1.Promotion>(this._address + "/promotion.v1.PromotionService/GetPromotionByID", message, metadata || {}, PromotionServiceClient.GetPromotionByID);
        }
        private static UpdatePromotion = new grpc_web_1.MethodDescriptor<dependency_3.promotion.v1.Promotion, dependency_1.google.protobuf.Empty>("/promotion.v1.PromotionService/UpdatePromotion", grpc_web_1.MethodType.UNARY, dependency_3.promotion.v1.Promotion, dependency_1.google.protobuf.Empty, (message: dependency_3.promotion.v1.Promotion) => message.serialize(), dependency_1.google.protobuf.Empty.deserialize);
        UpdatePromotion(message: dependency_3.promotion.v1.Promotion, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<dependency_3.promotion.v1.Promotion, dependency_1.google.protobuf.Empty>(this._address + "/promotion.v1.PromotionService/UpdatePromotion", message, metadata || {}, PromotionServiceClient.UpdatePromotion);
        }
        private static HasPromotion = new grpc_web_1.MethodDescriptor<HasPromotionRequest, HasPromotionResponse>("/promotion.v1.PromotionService/HasPromotion", grpc_web_1.MethodType.UNARY, HasPromotionRequest, HasPromotionResponse, (message: HasPromotionRequest) => message.serialize(), HasPromotionResponse.deserialize);
        HasPromotion(message: HasPromotionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<HasPromotionRequest, HasPromotionResponse>(this._address + "/promotion.v1.PromotionService/HasPromotion", message, metadata || {}, PromotionServiceClient.HasPromotion);
        }
        private static SearchPromotions = new grpc_web_1.MethodDescriptor<SearchPromotionsRequest, SearchPromotionsResponse>("/promotion.v1.PromotionService/SearchPromotions", grpc_web_1.MethodType.UNARY, SearchPromotionsRequest, SearchPromotionsResponse, (message: SearchPromotionsRequest) => message.serialize(), SearchPromotionsResponse.deserialize);
        SearchPromotions(message: SearchPromotionsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<SearchPromotionsRequest, SearchPromotionsResponse>(this._address + "/promotion.v1.PromotionService/SearchPromotions", message, metadata || {}, PromotionServiceClient.SearchPromotions);
        }
        private static GetSearchFilterPromotions = new grpc_web_1.MethodDescriptor<GetSearchFilterPromotionsRequest, GetSearchFilterPromotionsResponse>("/promotion.v1.PromotionService/GetSearchFilterPromotions", grpc_web_1.MethodType.UNARY, GetSearchFilterPromotionsRequest, GetSearchFilterPromotionsResponse, (message: GetSearchFilterPromotionsRequest) => message.serialize(), GetSearchFilterPromotionsResponse.deserialize);
        GetSearchFilterPromotions(message: GetSearchFilterPromotionsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetSearchFilterPromotionsRequest, GetSearchFilterPromotionsResponse>(this._address + "/promotion.v1.PromotionService/GetSearchFilterPromotions", message, metadata || {}, PromotionServiceClient.GetSearchFilterPromotions);
        }
        private static SavePromotion = new grpc_web_1.MethodDescriptor<SavePromotionRequest, SavePromotionResponse>("/promotion.v1.PromotionService/SavePromotion", grpc_web_1.MethodType.UNARY, SavePromotionRequest, SavePromotionResponse, (message: SavePromotionRequest) => message.serialize(), SavePromotionResponse.deserialize);
        SavePromotion(message: SavePromotionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<SavePromotionRequest, SavePromotionResponse>(this._address + "/promotion.v1.PromotionService/SavePromotion", message, metadata || {}, PromotionServiceClient.SavePromotion);
        }
    }
}
