import { ChangeDetectorRef, Directive, Input, OnInit, Renderer2, ElementRef } from "@angular/core";

@Directive({
  selector: "[img]",
  host: {
    "[attr.src]": "finalImage",
  },
})
export class ImagePreLoaderDirective implements OnInit {
  @Input("img") targetSource: string = "";
  defaultImage = "assets/gifs/loading.gif";
  downloadingImage: any;
  finalImage: any;
  oldImage: any;

  constructor(public ref: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    this.handleImage();
  }

  handleImage() {
    this.finalImage = this.defaultImage;

    if (!this.targetSource) {
      this.finalImage = "assets/images/placeholders/placeholder.png";
      this.oldImage = this.finalImage;
      this.renderer.addClass(this.el.nativeElement, 'placeholder-image');
      this.ref.detectChanges();
    } else {
      this.downloadingImage = new Image();
      this.downloadingImage.onload = () => {
        this.finalImage = this.targetSource;
        this.oldImage = this.finalImage;
        this.renderer.removeClass(this.el.nativeElement, 'placeholder-image');
        this.ref.detectChanges();
      };
      this.downloadingImage.onerror = (err: any) => {
        this.finalImage = "assets/images/placeholders/placeholder.png";
        this.oldImage = this.finalImage;
        this.renderer.addClass(this.el.nativeElement, 'placeholder-image');
        this.ref.detectChanges();
      };
      this.downloadingImage.src = this.targetSource;
    }
  }
}