import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { BaseComponent } from "../base/base.component";
import { ModalController } from "@ionic/angular";
import { BaseModalComponent } from "../modals/base-modal/base-modal.component";

@Component({
  selector: "app-footer-cart",
  templateUrl: "./footer-cart.component.html",
  styleUrls: ["./footer-cart.component.scss"],
})
export class FooterCartComponent extends BaseModalComponent {
  @Input() labelButton = "Continuar";
  @Input() label = "Subtotal";
  @Input() value;
  @Input() points;
  @Input() disabled: boolean;
  @Input() isSummary = false;
  @Output("action") action = new EventEmitter();

  subscription: Subscription;

  constructor() {
    super();
  }

  clickBtn() {
    if (this.shared.web) {
      this.closeModal("", null, () => {
        console.log("closeModal executado");
        this.action.emit();
      });
    } else {
      this.action.emit();
    }
  }

  handleCancelResponse(res) {
    console.log("handleCancelResponse: ", res);
    if (res.status) {
      this.system.showToast("Edição de pedido cancelada com sucesso");
      this.cart.initCart().subscribe(() => {});
      this.openPage([
        `tabs/orders/${this.cart.data.orderToken}`,
        {
          back: "tabs/home",
        },
      ]);
    } else {
      this.system.showErrorAlert(res);
    }
  }

  askCancel() {
    this.system.showAlert(
      "default",
      "Cancelar edição",
      "Deseja realmente cancelar a edição do pedido?",
      "Sim",
      async () => {
        const loading = await this.system.loadingCtrl.create({});
        loading.present();
        this.shared.post("checkout/cancel/edit-order", {}).subscribe((res) => {
          loading.dismiss();
          if (this.shared.web) {
            this.closeModal("", null, () => {
              console.log("closeModal executado");
              this.handleCancelResponse(res);
            });
          } else {
            this.handleCancelResponse(res);
          }
        });
      },
      "Não",
      () => {}
    );
  }
}
