<ion-header class="ion-no-border" [ngClass]="{ 'background-color': backgroundColor }">
  <ion-toolbar class="ion-no-border">
    <ion-title>{{ titleModal }}</ion-title>
    <ion-button (click)="closeModal()" slot="end" fill="clear" class="close" *ngIf="showClose">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
<div class="container-modal" [ngClass]="{ 'background-color': backgroundColor }">
  <ng-content></ng-content>
</div>
