<ion-content *ngIf="!shared.web">
  <div class="container-modal" [ngClass]="{ primary: primary }">
    <ion-refresher slot="fixed" [pullFactor]="1" [pullMin]="50" [pullMax]="300" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="tarja" *ngIf="cartData && cartData.remainingMinimumOrder && !isEmpty">
      <p>Faltam R$ {{ cartData.remainingMinimumOrder / 100 | number : "1.2-2" }} para o pedido mínimo</p>
    </div>
    <div class="container-products">
      <p class="empty-message" *ngIf="isEmpty">Você ainda não adicionou nenhum produto no carrinho.</p>
      <app-products type="cart" view="vertical" *ngIf="!isEmpty"></app-products>
    </div>
    <ion-button (click)="goHome()" expand="block" class="add-more-items">Adicionar mais itens </ion-button>
    <app-suggested-order label="Aproveite e compre também" labelSize="1.3em" *ngIf="!isEmpty"> </app-suggested-order>
  </div>
</ion-content>

<app-base-modal class="container-modal" *ngIf="shared.web" [titleModal]="title" [backgroundColor]="true">
  <div class="tarja" *ngIf="cartData && cartData.remainingMinimumOrder && !isEmpty">
    <p>Faltam R$ {{ cartData.remainingMinimumOrder / 100 | number : "1.2-2" }} para o pedido mínimo</p>
  </div>
  <div class="container-products">
    <p class="empty-message-web" *ngIf="isEmpty">Você ainda não adicionou nenhum produto no carrinho.</p>
    <app-products type="cart" view="vertical" *ngIf="!isEmpty"></app-products>
  </div>
  <ion-button (click)="goHomeFromWeb()" expand="block" class="add-more-items web">Adicionar mais itens </ion-button>
  <div *ngIf="!isEmpty" style="margin-top: 2em">
    <app-footer-cart (action)="next()" label="Subtotal" [value]="cartData.subtotal" [points]="cartData.totalPoints" [disabled]="isEmpty"></app-footer-cart>
  </div>
</app-base-modal>
