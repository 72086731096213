import { ProductAttribute } from "./product";

export const PROMOTION_CATEGORY: ProductAttribute = {
  id: "promotions",
  categoryId: "promotions",
  label: "Promoções",
  images: [
    {
      url: "assets/svgs/promotion.svg",
    },
  ],
  image: "assets/svgs/promotion.svg",
};
export interface PromotionParams {
  sku?: string;
  term?: string;
  flavorIds?: string[];
  paymentConditionCode?: string;
}

export interface Promotion {
  clientId: string;
  sku: string;
  promotionDescription: string;
  type: number;
  typeDescription: string;
  items: Item[];
  image: Image;
  brands: Brand[];
  price: number;
  points: number;
  max: number;
  min: number;
}

interface Item {
  productId: string;
  label?: string;
  sku: string;
  quantity: number;
  required: boolean;
  min: number;
  max: number;
  isRedeemable: boolean;
  isOnlyRedeemable: boolean;
  description: string;
  attributes: Attributes;
  customAttributes: CustomAttributes;
  unitLabelSingular: string;
  unitLabelPlural: string;
  categories: Category[];
  scalablePrices: any[];
  paymentCondition: string;
  paymentConditionDescription: string;
  redeemOriginalPoints: number;
  redeemPoints: number;
  position: number;
  flavor: Flavor;
  images: Image[];
}

interface Attributes {
  returnable: boolean;
  returnableMessage: string;
  package: string;
  weight: number;
  mandatory: boolean;
  quantityPerPackage: number;
  capacityLabel: string;
  expressDelivery: boolean;
  combo: boolean;
  kit: boolean;
}

interface CustomAttributes {
  srSapTipoOperacao: string;
  srSapTipoOperacaoExpressa: string;
  srSapAcao: boolean;
  srSapKit: boolean;
  srSapPolarizada: boolean;
  srSapMaterial: string;
}

interface Category {
  id: string;
  label: string;
  depth: number;
}

interface Flavor {
  id: string;
  name: string;
}

interface Image {
  url: string;
}

interface Brand {
  id: number;
  externalId: string;
  label: string;
  originalLabel: string;
  categoryId: number;
  categoryLabel: string;
  images: Image[];
}
