import { BehaviorSubject, firstValueFrom } from "rxjs";
import { filter, take } from "rxjs/operators";
import { Injectable, inject } from "@angular/core";
import { Request, UnaryInterceptor, UnaryResponse } from "grpc-web";
import { SharedService } from "./shared.service";

type RequestType = Request<any, any>;
type InvokerType = (request: RequestType) => Promise<UnaryResponse<any, any>>;

@Injectable()
export class AuthGrpcInterceptor implements UnaryInterceptor<RequestType, InvokerType> {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  protected shared = inject(SharedService);

  intercept(request: RequestType, invoker: InvokerType) {
    const token = this.shared.accessToken;
    if (token) {
      request.getMetadata()["Authorization"] = "Bearer " + token;
    }
    return invoker(request).catch(async (error: any) => {
      console.log("error caught", error);
      if (error.code === 16 || error.code === 4 || error.code === 401 || error.code === 0) {
        return this.handle401UnaryError(request, invoker);
      }
      throw error;
    });
  }

  private async handle401UnaryError(request: RequestType, invoker: InvokerType): Promise<UnaryResponse<any, any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      try {
        const refreshResponse = await firstValueFrom(this.shared.refresh());
        console.log("refreshResponse: ", refreshResponse);
        this.isRefreshing = false;
        if (refreshResponse.status) {
          this.refreshTokenSubject.next(this.shared.accessToken);
          request.getMetadata()["Authorization"] = "Bearer " + this.shared.accessToken;
          return invoker(request);
        } else {
          this.shared.logout("REFRESH_TOKEN_STATUS_ERROR_GRPC").subscribe();
          throw new Error("Token refresh failed");
        }
      } catch (err) {
        console.error("Algo de errado aconteceu: ", err);
        this.isRefreshing = false;
        this.shared.logout("REFRESH_TOKEN_ERROR_GRPC").subscribe();
        throw err;
      }
    } else {
      return new Promise((resolve, reject) => {
        this.refreshTokenSubject
          .pipe(
            filter((token) => token != null),
            take(1)
          )
          .subscribe({
            next: (token) => {
              request.getMetadata()["Authorization"] = "Bearer " + token;
              resolve(invoker(request));
            },
            error: (err) => reject(err),
          });
      });
    }
  }
}
