import { Component, EventEmitter, NgZone, OnInit, Output } from "@angular/core";
import { trigger, state, style, animate, transition, AnimationEvent } from "@angular/animations";
import { Product } from "src/app/models/product";
import { BaseComponent } from "../base/base.component";
import { GrpcPromotionService } from "@app/services/grpc-promotion.service";
import { Promotion, PROMOTION_CATEGORY } from "@app/models/promotion";

@Component({
  selector: "app-search-products",
  templateUrl: "./search-products.component.html",
  styleUrls: ["./search-products.component.scss"],
  animations: [
    trigger("fadeOut", [
      state("visible", style({ opacity: 1, height: "*" })),
      state("invisible", style({ opacity: 0, height: "0px", overflow: "hidden" })),
      transition("visible => invisible", animate("500ms ease-out")),
    ]),
  ],
})
export class SearchProductsComponent extends BaseComponent implements OnInit {
  filteredProducts: Product[];

  fadeOutState: "visible" | "invisible" = "invisible";

  loading = true;

  q: string;

  isPP: boolean;

  @Output() callback = new EventEmitter<void>();

  constructor(public ngZone: NgZone, public grpcPromotion: GrpcPromotionService) {
    super();
  }

  ngOnInit() {
    this.isPP = this.handleIsPP();
  }

  ionViewWillEnter() {
    this.isPP = this.handleIsPP();
  }

  filterList(ev: any) {
    const value = ev.detail.value;
    if (value.length >= 3) {
      this.getProducts(value);
      this.fadeOutState = "visible";
      this.ref.detectChanges();
    } else {
      this.fadeOutState = "invisible";
      this.ref.detectChanges();
    }
  }

  getProducts(q: string) {
    this.q = q;
    const isPromotion = this.cart.currentCategory?.id === "promotions";
    this.loading = true;
    if (isPromotion) {
      this.grpcPromotion
        .getPromotions({ term: q })
        .then((response) => {
          this.loading = false;
          const res = response.toObject();
          if (res && res.promotions) {
            const productsPromotions = this.cart.promotionsToProducts(res.promotions as Promotion[]);
            console.log("Promotions DTO: ", productsPromotions);
            if (productsPromotions) {
              this.filteredProducts = productsPromotions;
            } else {
              this.filteredProducts = [];
            }
          } else {
            this.filteredProducts = [];
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.filteredProducts = [];
        });
    } else {
      this.cart.getProducts("", "", "", q).subscribe((products) => {
        this.loading = false;
        if (products) {
          this.filteredProducts = products;
        } else {
          this.filteredProducts = [];
        }
      });
    }
  }

  async selectFilterdItem(product: Product) {
    if (product.promotional) {
      this.cart.currentCategory = PROMOTION_CATEGORY;
      this.cart.currentProduct$.next(product);
      this.fadeOutState = "invisible";
      this.q = "";
      this.ref.detectChanges();
      this.system.navCtrl.navigateForward("tabs/catalog/products/details");
    } else {
      const loading = await this.system.loadingCtrl.create({});
      loading.present();
      if (product.categories) {
        this.cart.currentFlavor = null;
        this.cart.currentCategory = product.categories[0];
        this.cart.currentBrand = product.categories[1];
        this.cart.getProducts(this.cart.currentCategory.id, this.cart.currentBrand.id).subscribe((products) => {
          loading.dismiss();
          this.callback.emit();
          this.ngZone.runOutsideAngular(() => {
            this.cart.products.set(products);
            this.fadeOutState = "invisible";
            this.ref.detectChanges();
          });
        });
      } else {
        console.error("product.categories deveria existir");
      }
    }
  }

  animationDone(event: AnimationEvent): void {
    if (event.toState === "invisible") {
    }
  }
}
