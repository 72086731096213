/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: promotion/model.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace promotion.v1 {
    export enum PromotionType {
        UNKNOWN = 0,
        KIT = 1,
        COMBO = 2
    }
    export class Promotion extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            clientId?: string;
            sku?: string;
            promotionDescription?: string;
            type?: PromotionType;
            typeDescription?: string;
            items?: PromotionItem[];
            image?: Image;
            brands?: Brand[];
            price?: number;
            points?: number;
            max?: number;
            min?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6, 8], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("clientId" in data && data.clientId != undefined) {
                    this.clientId = data.clientId;
                }
                if ("sku" in data && data.sku != undefined) {
                    this.sku = data.sku;
                }
                if ("promotionDescription" in data && data.promotionDescription != undefined) {
                    this.promotionDescription = data.promotionDescription;
                }
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("typeDescription" in data && data.typeDescription != undefined) {
                    this.typeDescription = data.typeDescription;
                }
                if ("items" in data && data.items != undefined) {
                    this.items = data.items;
                }
                if ("image" in data && data.image != undefined) {
                    this.image = data.image;
                }
                if ("brands" in data && data.brands != undefined) {
                    this.brands = data.brands;
                }
                if ("price" in data && data.price != undefined) {
                    this.price = data.price;
                }
                if ("points" in data && data.points != undefined) {
                    this.points = data.points;
                }
                if ("max" in data && data.max != undefined) {
                    this.max = data.max;
                }
                if ("min" in data && data.min != undefined) {
                    this.min = data.min;
                }
            }
        }
        get clientId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set clientId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get sku() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set sku(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get promotionDescription() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set promotionDescription(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 4, PromotionType.UNKNOWN) as PromotionType;
        }
        set type(value: PromotionType) {
            pb_1.Message.setField(this, 4, value);
        }
        get typeDescription() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set typeDescription(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get items() {
            return pb_1.Message.getRepeatedWrapperField(this, PromotionItem, 6) as PromotionItem[];
        }
        set items(value: PromotionItem[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get image() {
            return pb_1.Message.getWrapperField(this, Image, 7) as Image;
        }
        set image(value: Image) {
            pb_1.Message.setWrapperField(this, 7, value);
        }
        get hasImage() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get brands() {
            return pb_1.Message.getRepeatedWrapperField(this, Brand, 8) as Brand[];
        }
        set brands(value: Brand[]) {
            pb_1.Message.setRepeatedWrapperField(this, 8, value);
        }
        get price() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set price(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get points() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set points(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        get max() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set max(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get min() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set min(value: number) {
            pb_1.Message.setField(this, 12, value);
        }
        static fromObject(data: {
            clientId?: string;
            sku?: string;
            promotionDescription?: string;
            type?: PromotionType;
            typeDescription?: string;
            items?: ReturnType<typeof PromotionItem.prototype.toObject>[];
            image?: ReturnType<typeof Image.prototype.toObject>;
            brands?: ReturnType<typeof Brand.prototype.toObject>[];
            price?: number;
            points?: number;
            max?: number;
            min?: number;
        }): Promotion {
            const message = new Promotion({});
            if (data.clientId != null) {
                message.clientId = data.clientId;
            }
            if (data.sku != null) {
                message.sku = data.sku;
            }
            if (data.promotionDescription != null) {
                message.promotionDescription = data.promotionDescription;
            }
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.typeDescription != null) {
                message.typeDescription = data.typeDescription;
            }
            if (data.items != null) {
                message.items = data.items.map(item => PromotionItem.fromObject(item));
            }
            if (data.image != null) {
                message.image = Image.fromObject(data.image);
            }
            if (data.brands != null) {
                message.brands = data.brands.map(item => Brand.fromObject(item));
            }
            if (data.price != null) {
                message.price = data.price;
            }
            if (data.points != null) {
                message.points = data.points;
            }
            if (data.max != null) {
                message.max = data.max;
            }
            if (data.min != null) {
                message.min = data.min;
            }
            return message;
        }
        toObject() {
            const data: {
                clientId?: string;
                sku?: string;
                promotionDescription?: string;
                type?: PromotionType;
                typeDescription?: string;
                items?: ReturnType<typeof PromotionItem.prototype.toObject>[];
                image?: ReturnType<typeof Image.prototype.toObject>;
                brands?: ReturnType<typeof Brand.prototype.toObject>[];
                price?: number;
                points?: number;
                max?: number;
                min?: number;
            } = {};
            if (this.clientId != null) {
                data.clientId = this.clientId;
            }
            if (this.sku != null) {
                data.sku = this.sku;
            }
            if (this.promotionDescription != null) {
                data.promotionDescription = this.promotionDescription;
            }
            if (this.type != null) {
                data.type = this.type;
            }
            if (this.typeDescription != null) {
                data.typeDescription = this.typeDescription;
            }
            if (this.items != null) {
                data.items = this.items.map((item: PromotionItem) => item.toObject());
            }
            if (this.image != null) {
                data.image = this.image.toObject();
            }
            if (this.brands != null) {
                data.brands = this.brands.map((item: Brand) => item.toObject());
            }
            if (this.price != null) {
                data.price = this.price;
            }
            if (this.points != null) {
                data.points = this.points;
            }
            if (this.max != null) {
                data.max = this.max;
            }
            if (this.min != null) {
                data.min = this.min;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.clientId.length)
                writer.writeString(1, this.clientId);
            if (this.sku.length)
                writer.writeString(2, this.sku);
            if (this.promotionDescription.length)
                writer.writeString(3, this.promotionDescription);
            if (this.type != PromotionType.UNKNOWN)
                writer.writeEnum(4, this.type);
            if (this.typeDescription.length)
                writer.writeString(5, this.typeDescription);
            if (this.items.length)
                writer.writeRepeatedMessage(6, this.items, (item: PromotionItem) => item.serialize(writer));
            if (this.hasImage)
                writer.writeMessage(7, this.image, () => this.image.serialize(writer));
            if (this.brands.length)
                writer.writeRepeatedMessage(8, this.brands, (item: Brand) => item.serialize(writer));
            if (this.price != 0)
                writer.writeInt32(9, this.price);
            if (this.points != 0)
                writer.writeInt32(10, this.points);
            if (this.max != 0)
                writer.writeInt32(11, this.max);
            if (this.min != 0)
                writer.writeInt32(12, this.min);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Promotion {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Promotion();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.clientId = reader.readString();
                        break;
                    case 2:
                        message.sku = reader.readString();
                        break;
                    case 3:
                        message.promotionDescription = reader.readString();
                        break;
                    case 4:
                        message.type = reader.readEnum();
                        break;
                    case 5:
                        message.typeDescription = reader.readString();
                        break;
                    case 6:
                        reader.readMessage(message.items, () => pb_1.Message.addToRepeatedWrapperField(message, 6, PromotionItem.deserialize(reader), PromotionItem));
                        break;
                    case 7:
                        reader.readMessage(message.image, () => message.image = Image.deserialize(reader));
                        break;
                    case 8:
                        reader.readMessage(message.brands, () => pb_1.Message.addToRepeatedWrapperField(message, 8, Brand.deserialize(reader), Brand));
                        break;
                    case 9:
                        message.price = reader.readInt32();
                        break;
                    case 10:
                        message.points = reader.readInt32();
                        break;
                    case 11:
                        message.max = reader.readInt32();
                        break;
                    case 12:
                        message.min = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Promotion {
            return Promotion.deserialize(bytes);
        }
    }
    export class PromotionItem extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
            sku?: string;
            quantity?: number;
            required?: boolean;
            min?: number;
            max?: number;
            isRedeemable?: boolean;
            isOnlyRedeemable?: boolean;
            description?: string;
            attributes?: Attributes;
            customAttributes?: CustomAttributes;
            unitLabelSingular?: string;
            unitLabelPlural?: string;
            categories?: Category[];
            scalablePrices?: ScalablePrice[];
            paymentCondition?: string;
            paymentConditionDescription?: string;
            redeemOriginalPoints?: number;
            redeemPoints?: number;
            position?: number;
            flavor?: Flavor;
            images?: Image[];
            label?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [14, 15, 22], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("sku" in data && data.sku != undefined) {
                    this.sku = data.sku;
                }
                if ("quantity" in data && data.quantity != undefined) {
                    this.quantity = data.quantity;
                }
                if ("required" in data && data.required != undefined) {
                    this.required = data.required;
                }
                if ("min" in data && data.min != undefined) {
                    this.min = data.min;
                }
                if ("max" in data && data.max != undefined) {
                    this.max = data.max;
                }
                if ("isRedeemable" in data && data.isRedeemable != undefined) {
                    this.isRedeemable = data.isRedeemable;
                }
                if ("isOnlyRedeemable" in data && data.isOnlyRedeemable != undefined) {
                    this.isOnlyRedeemable = data.isOnlyRedeemable;
                }
                if ("description" in data && data.description != undefined) {
                    this.description = data.description;
                }
                if ("attributes" in data && data.attributes != undefined) {
                    this.attributes = data.attributes;
                }
                if ("customAttributes" in data && data.customAttributes != undefined) {
                    this.customAttributes = data.customAttributes;
                }
                if ("unitLabelSingular" in data && data.unitLabelSingular != undefined) {
                    this.unitLabelSingular = data.unitLabelSingular;
                }
                if ("unitLabelPlural" in data && data.unitLabelPlural != undefined) {
                    this.unitLabelPlural = data.unitLabelPlural;
                }
                if ("categories" in data && data.categories != undefined) {
                    this.categories = data.categories;
                }
                if ("scalablePrices" in data && data.scalablePrices != undefined) {
                    this.scalablePrices = data.scalablePrices;
                }
                if ("paymentCondition" in data && data.paymentCondition != undefined) {
                    this.paymentCondition = data.paymentCondition;
                }
                if ("paymentConditionDescription" in data && data.paymentConditionDescription != undefined) {
                    this.paymentConditionDescription = data.paymentConditionDescription;
                }
                if ("redeemOriginalPoints" in data && data.redeemOriginalPoints != undefined) {
                    this.redeemOriginalPoints = data.redeemOriginalPoints;
                }
                if ("redeemPoints" in data && data.redeemPoints != undefined) {
                    this.redeemPoints = data.redeemPoints;
                }
                if ("position" in data && data.position != undefined) {
                    this.position = data.position;
                }
                if ("flavor" in data && data.flavor != undefined) {
                    this.flavor = data.flavor;
                }
                if ("images" in data && data.images != undefined) {
                    this.images = data.images;
                }
                if ("label" in data && data.label != undefined) {
                    this.label = data.label;
                }
            }
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get sku() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set sku(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get quantity() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set quantity(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get required() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set required(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        get min() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set min(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get max() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set max(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get isRedeemable() {
            return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
        }
        set isRedeemable(value: boolean) {
            pb_1.Message.setField(this, 7, value);
        }
        get isOnlyRedeemable() {
            return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
        }
        set isOnlyRedeemable(value: boolean) {
            pb_1.Message.setField(this, 8, value);
        }
        get description() {
            return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
        }
        set description(value: string) {
            pb_1.Message.setField(this, 9, value);
        }
        get attributes() {
            return pb_1.Message.getWrapperField(this, Attributes, 10) as Attributes;
        }
        set attributes(value: Attributes) {
            pb_1.Message.setWrapperField(this, 10, value);
        }
        get hasAttributes() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get customAttributes() {
            return pb_1.Message.getWrapperField(this, CustomAttributes, 11) as CustomAttributes;
        }
        set customAttributes(value: CustomAttributes) {
            pb_1.Message.setWrapperField(this, 11, value);
        }
        get hasCustomAttributes() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get unitLabelSingular() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set unitLabelSingular(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get unitLabelPlural() {
            return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
        }
        set unitLabelPlural(value: string) {
            pb_1.Message.setField(this, 13, value);
        }
        get categories() {
            return pb_1.Message.getRepeatedWrapperField(this, Category, 14) as Category[];
        }
        set categories(value: Category[]) {
            pb_1.Message.setRepeatedWrapperField(this, 14, value);
        }
        get scalablePrices() {
            return pb_1.Message.getRepeatedWrapperField(this, ScalablePrice, 15) as ScalablePrice[];
        }
        set scalablePrices(value: ScalablePrice[]) {
            pb_1.Message.setRepeatedWrapperField(this, 15, value);
        }
        get paymentCondition() {
            return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
        }
        set paymentCondition(value: string) {
            pb_1.Message.setField(this, 16, value);
        }
        get paymentConditionDescription() {
            return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
        }
        set paymentConditionDescription(value: string) {
            pb_1.Message.setField(this, 17, value);
        }
        get redeemOriginalPoints() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set redeemOriginalPoints(value: number) {
            pb_1.Message.setField(this, 18, value);
        }
        get redeemPoints() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set redeemPoints(value: number) {
            pb_1.Message.setField(this, 19, value);
        }
        get position() {
            return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
        }
        set position(value: number) {
            pb_1.Message.setField(this, 20, value);
        }
        get flavor() {
            return pb_1.Message.getWrapperField(this, Flavor, 21) as Flavor;
        }
        set flavor(value: Flavor) {
            pb_1.Message.setWrapperField(this, 21, value);
        }
        get hasFlavor() {
            return pb_1.Message.getField(this, 21) != null;
        }
        get images() {
            return pb_1.Message.getRepeatedWrapperField(this, Image, 22) as Image[];
        }
        set images(value: Image[]) {
            pb_1.Message.setRepeatedWrapperField(this, 22, value);
        }
        get label() {
            return pb_1.Message.getFieldWithDefault(this, 23, "") as string;
        }
        set label(value: string) {
            pb_1.Message.setField(this, 23, value);
        }
        static fromObject(data: {
            productId?: string;
            sku?: string;
            quantity?: number;
            required?: boolean;
            min?: number;
            max?: number;
            isRedeemable?: boolean;
            isOnlyRedeemable?: boolean;
            description?: string;
            attributes?: ReturnType<typeof Attributes.prototype.toObject>;
            customAttributes?: ReturnType<typeof CustomAttributes.prototype.toObject>;
            unitLabelSingular?: string;
            unitLabelPlural?: string;
            categories?: ReturnType<typeof Category.prototype.toObject>[];
            scalablePrices?: ReturnType<typeof ScalablePrice.prototype.toObject>[];
            paymentCondition?: string;
            paymentConditionDescription?: string;
            redeemOriginalPoints?: number;
            redeemPoints?: number;
            position?: number;
            flavor?: ReturnType<typeof Flavor.prototype.toObject>;
            images?: ReturnType<typeof Image.prototype.toObject>[];
            label?: string;
        }): PromotionItem {
            const message = new PromotionItem({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (data.sku != null) {
                message.sku = data.sku;
            }
            if (data.quantity != null) {
                message.quantity = data.quantity;
            }
            if (data.required != null) {
                message.required = data.required;
            }
            if (data.min != null) {
                message.min = data.min;
            }
            if (data.max != null) {
                message.max = data.max;
            }
            if (data.isRedeemable != null) {
                message.isRedeemable = data.isRedeemable;
            }
            if (data.isOnlyRedeemable != null) {
                message.isOnlyRedeemable = data.isOnlyRedeemable;
            }
            if (data.description != null) {
                message.description = data.description;
            }
            if (data.attributes != null) {
                message.attributes = Attributes.fromObject(data.attributes);
            }
            if (data.customAttributes != null) {
                message.customAttributes = CustomAttributes.fromObject(data.customAttributes);
            }
            if (data.unitLabelSingular != null) {
                message.unitLabelSingular = data.unitLabelSingular;
            }
            if (data.unitLabelPlural != null) {
                message.unitLabelPlural = data.unitLabelPlural;
            }
            if (data.categories != null) {
                message.categories = data.categories.map(item => Category.fromObject(item));
            }
            if (data.scalablePrices != null) {
                message.scalablePrices = data.scalablePrices.map(item => ScalablePrice.fromObject(item));
            }
            if (data.paymentCondition != null) {
                message.paymentCondition = data.paymentCondition;
            }
            if (data.paymentConditionDescription != null) {
                message.paymentConditionDescription = data.paymentConditionDescription;
            }
            if (data.redeemOriginalPoints != null) {
                message.redeemOriginalPoints = data.redeemOriginalPoints;
            }
            if (data.redeemPoints != null) {
                message.redeemPoints = data.redeemPoints;
            }
            if (data.position != null) {
                message.position = data.position;
            }
            if (data.flavor != null) {
                message.flavor = Flavor.fromObject(data.flavor);
            }
            if (data.images != null) {
                message.images = data.images.map(item => Image.fromObject(item));
            }
            if (data.label != null) {
                message.label = data.label;
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
                sku?: string;
                quantity?: number;
                required?: boolean;
                min?: number;
                max?: number;
                isRedeemable?: boolean;
                isOnlyRedeemable?: boolean;
                description?: string;
                attributes?: ReturnType<typeof Attributes.prototype.toObject>;
                customAttributes?: ReturnType<typeof CustomAttributes.prototype.toObject>;
                unitLabelSingular?: string;
                unitLabelPlural?: string;
                categories?: ReturnType<typeof Category.prototype.toObject>[];
                scalablePrices?: ReturnType<typeof ScalablePrice.prototype.toObject>[];
                paymentCondition?: string;
                paymentConditionDescription?: string;
                redeemOriginalPoints?: number;
                redeemPoints?: number;
                position?: number;
                flavor?: ReturnType<typeof Flavor.prototype.toObject>;
                images?: ReturnType<typeof Image.prototype.toObject>[];
                label?: string;
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.sku != null) {
                data.sku = this.sku;
            }
            if (this.quantity != null) {
                data.quantity = this.quantity;
            }
            if (this.required != null) {
                data.required = this.required;
            }
            if (this.min != null) {
                data.min = this.min;
            }
            if (this.max != null) {
                data.max = this.max;
            }
            if (this.isRedeemable != null) {
                data.isRedeemable = this.isRedeemable;
            }
            if (this.isOnlyRedeemable != null) {
                data.isOnlyRedeemable = this.isOnlyRedeemable;
            }
            if (this.description != null) {
                data.description = this.description;
            }
            if (this.attributes != null) {
                data.attributes = this.attributes.toObject();
            }
            if (this.customAttributes != null) {
                data.customAttributes = this.customAttributes.toObject();
            }
            if (this.unitLabelSingular != null) {
                data.unitLabelSingular = this.unitLabelSingular;
            }
            if (this.unitLabelPlural != null) {
                data.unitLabelPlural = this.unitLabelPlural;
            }
            if (this.categories != null) {
                data.categories = this.categories.map((item: Category) => item.toObject());
            }
            if (this.scalablePrices != null) {
                data.scalablePrices = this.scalablePrices.map((item: ScalablePrice) => item.toObject());
            }
            if (this.paymentCondition != null) {
                data.paymentCondition = this.paymentCondition;
            }
            if (this.paymentConditionDescription != null) {
                data.paymentConditionDescription = this.paymentConditionDescription;
            }
            if (this.redeemOriginalPoints != null) {
                data.redeemOriginalPoints = this.redeemOriginalPoints;
            }
            if (this.redeemPoints != null) {
                data.redeemPoints = this.redeemPoints;
            }
            if (this.position != null) {
                data.position = this.position;
            }
            if (this.flavor != null) {
                data.flavor = this.flavor.toObject();
            }
            if (this.images != null) {
                data.images = this.images.map((item: Image) => item.toObject());
            }
            if (this.label != null) {
                data.label = this.label;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            if (this.sku.length)
                writer.writeString(2, this.sku);
            if (this.quantity != 0)
                writer.writeInt32(3, this.quantity);
            if (this.required != false)
                writer.writeBool(4, this.required);
            if (this.min != 0)
                writer.writeInt32(5, this.min);
            if (this.max != 0)
                writer.writeInt32(6, this.max);
            if (this.isRedeemable != false)
                writer.writeBool(7, this.isRedeemable);
            if (this.isOnlyRedeemable != false)
                writer.writeBool(8, this.isOnlyRedeemable);
            if (this.description.length)
                writer.writeString(9, this.description);
            if (this.hasAttributes)
                writer.writeMessage(10, this.attributes, () => this.attributes.serialize(writer));
            if (this.hasCustomAttributes)
                writer.writeMessage(11, this.customAttributes, () => this.customAttributes.serialize(writer));
            if (this.unitLabelSingular.length)
                writer.writeString(12, this.unitLabelSingular);
            if (this.unitLabelPlural.length)
                writer.writeString(13, this.unitLabelPlural);
            if (this.categories.length)
                writer.writeRepeatedMessage(14, this.categories, (item: Category) => item.serialize(writer));
            if (this.scalablePrices.length)
                writer.writeRepeatedMessage(15, this.scalablePrices, (item: ScalablePrice) => item.serialize(writer));
            if (this.paymentCondition.length)
                writer.writeString(16, this.paymentCondition);
            if (this.paymentConditionDescription.length)
                writer.writeString(17, this.paymentConditionDescription);
            if (this.redeemOriginalPoints != 0)
                writer.writeInt32(18, this.redeemOriginalPoints);
            if (this.redeemPoints != 0)
                writer.writeInt32(19, this.redeemPoints);
            if (this.position != 0)
                writer.writeInt32(20, this.position);
            if (this.hasFlavor)
                writer.writeMessage(21, this.flavor, () => this.flavor.serialize(writer));
            if (this.images.length)
                writer.writeRepeatedMessage(22, this.images, (item: Image) => item.serialize(writer));
            if (this.label.length)
                writer.writeString(23, this.label);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PromotionItem {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PromotionItem();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    case 2:
                        message.sku = reader.readString();
                        break;
                    case 3:
                        message.quantity = reader.readInt32();
                        break;
                    case 4:
                        message.required = reader.readBool();
                        break;
                    case 5:
                        message.min = reader.readInt32();
                        break;
                    case 6:
                        message.max = reader.readInt32();
                        break;
                    case 7:
                        message.isRedeemable = reader.readBool();
                        break;
                    case 8:
                        message.isOnlyRedeemable = reader.readBool();
                        break;
                    case 9:
                        message.description = reader.readString();
                        break;
                    case 10:
                        reader.readMessage(message.attributes, () => message.attributes = Attributes.deserialize(reader));
                        break;
                    case 11:
                        reader.readMessage(message.customAttributes, () => message.customAttributes = CustomAttributes.deserialize(reader));
                        break;
                    case 12:
                        message.unitLabelSingular = reader.readString();
                        break;
                    case 13:
                        message.unitLabelPlural = reader.readString();
                        break;
                    case 14:
                        reader.readMessage(message.categories, () => pb_1.Message.addToRepeatedWrapperField(message, 14, Category.deserialize(reader), Category));
                        break;
                    case 15:
                        reader.readMessage(message.scalablePrices, () => pb_1.Message.addToRepeatedWrapperField(message, 15, ScalablePrice.deserialize(reader), ScalablePrice));
                        break;
                    case 16:
                        message.paymentCondition = reader.readString();
                        break;
                    case 17:
                        message.paymentConditionDescription = reader.readString();
                        break;
                    case 18:
                        message.redeemOriginalPoints = reader.readInt32();
                        break;
                    case 19:
                        message.redeemPoints = reader.readInt32();
                        break;
                    case 20:
                        message.position = reader.readInt32();
                        break;
                    case 21:
                        reader.readMessage(message.flavor, () => message.flavor = Flavor.deserialize(reader));
                        break;
                    case 22:
                        reader.readMessage(message.images, () => pb_1.Message.addToRepeatedWrapperField(message, 22, Image.deserialize(reader), Image));
                        break;
                    case 23:
                        message.label = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PromotionItem {
            return PromotionItem.deserialize(bytes);
        }
    }
    export class Attributes extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            returnable?: boolean;
            returnableMessage?: string;
            package?: string;
            weight?: number;
            mandatory?: boolean;
            quantityPerPackage?: number;
            capacityLabel?: string;
            expressDelivery?: boolean;
            combo?: boolean;
            kit?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("returnable" in data && data.returnable != undefined) {
                    this.returnable = data.returnable;
                }
                if ("returnableMessage" in data && data.returnableMessage != undefined) {
                    this.returnableMessage = data.returnableMessage;
                }
                if ("package" in data && data.package != undefined) {
                    this.package = data.package;
                }
                if ("weight" in data && data.weight != undefined) {
                    this.weight = data.weight;
                }
                if ("mandatory" in data && data.mandatory != undefined) {
                    this.mandatory = data.mandatory;
                }
                if ("quantityPerPackage" in data && data.quantityPerPackage != undefined) {
                    this.quantityPerPackage = data.quantityPerPackage;
                }
                if ("capacityLabel" in data && data.capacityLabel != undefined) {
                    this.capacityLabel = data.capacityLabel;
                }
                if ("expressDelivery" in data && data.expressDelivery != undefined) {
                    this.expressDelivery = data.expressDelivery;
                }
                if ("combo" in data && data.combo != undefined) {
                    this.combo = data.combo;
                }
                if ("kit" in data && data.kit != undefined) {
                    this.kit = data.kit;
                }
            }
        }
        get returnable() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set returnable(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get returnableMessage() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set returnableMessage(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get package() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set package(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get weight() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set weight(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get mandatory() {
            return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
        }
        set mandatory(value: boolean) {
            pb_1.Message.setField(this, 5, value);
        }
        get quantityPerPackage() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set quantityPerPackage(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get capacityLabel() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set capacityLabel(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get expressDelivery() {
            return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
        }
        set expressDelivery(value: boolean) {
            pb_1.Message.setField(this, 8, value);
        }
        get combo() {
            return pb_1.Message.getFieldWithDefault(this, 9, false) as boolean;
        }
        set combo(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        get kit() {
            return pb_1.Message.getFieldWithDefault(this, 10, false) as boolean;
        }
        set kit(value: boolean) {
            pb_1.Message.setField(this, 10, value);
        }
        static fromObject(data: {
            returnable?: boolean;
            returnableMessage?: string;
            package?: string;
            weight?: number;
            mandatory?: boolean;
            quantityPerPackage?: number;
            capacityLabel?: string;
            expressDelivery?: boolean;
            combo?: boolean;
            kit?: boolean;
        }): Attributes {
            const message = new Attributes({});
            if (data.returnable != null) {
                message.returnable = data.returnable;
            }
            if (data.returnableMessage != null) {
                message.returnableMessage = data.returnableMessage;
            }
            if (data.package != null) {
                message.package = data.package;
            }
            if (data.weight != null) {
                message.weight = data.weight;
            }
            if (data.mandatory != null) {
                message.mandatory = data.mandatory;
            }
            if (data.quantityPerPackage != null) {
                message.quantityPerPackage = data.quantityPerPackage;
            }
            if (data.capacityLabel != null) {
                message.capacityLabel = data.capacityLabel;
            }
            if (data.expressDelivery != null) {
                message.expressDelivery = data.expressDelivery;
            }
            if (data.combo != null) {
                message.combo = data.combo;
            }
            if (data.kit != null) {
                message.kit = data.kit;
            }
            return message;
        }
        toObject() {
            const data: {
                returnable?: boolean;
                returnableMessage?: string;
                package?: string;
                weight?: number;
                mandatory?: boolean;
                quantityPerPackage?: number;
                capacityLabel?: string;
                expressDelivery?: boolean;
                combo?: boolean;
                kit?: boolean;
            } = {};
            if (this.returnable != null) {
                data.returnable = this.returnable;
            }
            if (this.returnableMessage != null) {
                data.returnableMessage = this.returnableMessage;
            }
            if (this.package != null) {
                data.package = this.package;
            }
            if (this.weight != null) {
                data.weight = this.weight;
            }
            if (this.mandatory != null) {
                data.mandatory = this.mandatory;
            }
            if (this.quantityPerPackage != null) {
                data.quantityPerPackage = this.quantityPerPackage;
            }
            if (this.capacityLabel != null) {
                data.capacityLabel = this.capacityLabel;
            }
            if (this.expressDelivery != null) {
                data.expressDelivery = this.expressDelivery;
            }
            if (this.combo != null) {
                data.combo = this.combo;
            }
            if (this.kit != null) {
                data.kit = this.kit;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.returnable != false)
                writer.writeBool(1, this.returnable);
            if (this.returnableMessage.length)
                writer.writeString(2, this.returnableMessage);
            if (this.package.length)
                writer.writeString(3, this.package);
            if (this.weight != 0)
                writer.writeDouble(4, this.weight);
            if (this.mandatory != false)
                writer.writeBool(5, this.mandatory);
            if (this.quantityPerPackage != 0)
                writer.writeInt32(6, this.quantityPerPackage);
            if (this.capacityLabel.length)
                writer.writeString(7, this.capacityLabel);
            if (this.expressDelivery != false)
                writer.writeBool(8, this.expressDelivery);
            if (this.combo != false)
                writer.writeBool(9, this.combo);
            if (this.kit != false)
                writer.writeBool(10, this.kit);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Attributes {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Attributes();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.returnable = reader.readBool();
                        break;
                    case 2:
                        message.returnableMessage = reader.readString();
                        break;
                    case 3:
                        message.package = reader.readString();
                        break;
                    case 4:
                        message.weight = reader.readDouble();
                        break;
                    case 5:
                        message.mandatory = reader.readBool();
                        break;
                    case 6:
                        message.quantityPerPackage = reader.readInt32();
                        break;
                    case 7:
                        message.capacityLabel = reader.readString();
                        break;
                    case 8:
                        message.expressDelivery = reader.readBool();
                        break;
                    case 9:
                        message.combo = reader.readBool();
                        break;
                    case 10:
                        message.kit = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Attributes {
            return Attributes.deserialize(bytes);
        }
    }
    export class CustomAttributes extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            srSapTipoOperacao?: string;
            srSapTipoOperacaoExpressa?: string;
            srSapAcao?: boolean;
            srSapKit?: boolean;
            srSapPolarizada?: boolean;
            srSapMaterial?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("srSapTipoOperacao" in data && data.srSapTipoOperacao != undefined) {
                    this.srSapTipoOperacao = data.srSapTipoOperacao;
                }
                if ("srSapTipoOperacaoExpressa" in data && data.srSapTipoOperacaoExpressa != undefined) {
                    this.srSapTipoOperacaoExpressa = data.srSapTipoOperacaoExpressa;
                }
                if ("srSapAcao" in data && data.srSapAcao != undefined) {
                    this.srSapAcao = data.srSapAcao;
                }
                if ("srSapKit" in data && data.srSapKit != undefined) {
                    this.srSapKit = data.srSapKit;
                }
                if ("srSapPolarizada" in data && data.srSapPolarizada != undefined) {
                    this.srSapPolarizada = data.srSapPolarizada;
                }
                if ("srSapMaterial" in data && data.srSapMaterial != undefined) {
                    this.srSapMaterial = data.srSapMaterial;
                }
            }
        }
        get srSapTipoOperacao() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set srSapTipoOperacao(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get srSapTipoOperacaoExpressa() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set srSapTipoOperacaoExpressa(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get srSapAcao() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set srSapAcao(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        get srSapKit() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set srSapKit(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        get srSapPolarizada() {
            return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
        }
        set srSapPolarizada(value: boolean) {
            pb_1.Message.setField(this, 5, value);
        }
        get srSapMaterial() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set srSapMaterial(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            srSapTipoOperacao?: string;
            srSapTipoOperacaoExpressa?: string;
            srSapAcao?: boolean;
            srSapKit?: boolean;
            srSapPolarizada?: boolean;
            srSapMaterial?: string;
        }): CustomAttributes {
            const message = new CustomAttributes({});
            if (data.srSapTipoOperacao != null) {
                message.srSapTipoOperacao = data.srSapTipoOperacao;
            }
            if (data.srSapTipoOperacaoExpressa != null) {
                message.srSapTipoOperacaoExpressa = data.srSapTipoOperacaoExpressa;
            }
            if (data.srSapAcao != null) {
                message.srSapAcao = data.srSapAcao;
            }
            if (data.srSapKit != null) {
                message.srSapKit = data.srSapKit;
            }
            if (data.srSapPolarizada != null) {
                message.srSapPolarizada = data.srSapPolarizada;
            }
            if (data.srSapMaterial != null) {
                message.srSapMaterial = data.srSapMaterial;
            }
            return message;
        }
        toObject() {
            const data: {
                srSapTipoOperacao?: string;
                srSapTipoOperacaoExpressa?: string;
                srSapAcao?: boolean;
                srSapKit?: boolean;
                srSapPolarizada?: boolean;
                srSapMaterial?: string;
            } = {};
            if (this.srSapTipoOperacao != null) {
                data.srSapTipoOperacao = this.srSapTipoOperacao;
            }
            if (this.srSapTipoOperacaoExpressa != null) {
                data.srSapTipoOperacaoExpressa = this.srSapTipoOperacaoExpressa;
            }
            if (this.srSapAcao != null) {
                data.srSapAcao = this.srSapAcao;
            }
            if (this.srSapKit != null) {
                data.srSapKit = this.srSapKit;
            }
            if (this.srSapPolarizada != null) {
                data.srSapPolarizada = this.srSapPolarizada;
            }
            if (this.srSapMaterial != null) {
                data.srSapMaterial = this.srSapMaterial;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.srSapTipoOperacao.length)
                writer.writeString(1, this.srSapTipoOperacao);
            if (this.srSapTipoOperacaoExpressa.length)
                writer.writeString(2, this.srSapTipoOperacaoExpressa);
            if (this.srSapAcao != false)
                writer.writeBool(3, this.srSapAcao);
            if (this.srSapKit != false)
                writer.writeBool(4, this.srSapKit);
            if (this.srSapPolarizada != false)
                writer.writeBool(5, this.srSapPolarizada);
            if (this.srSapMaterial.length)
                writer.writeString(6, this.srSapMaterial);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CustomAttributes {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CustomAttributes();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.srSapTipoOperacao = reader.readString();
                        break;
                    case 2:
                        message.srSapTipoOperacaoExpressa = reader.readString();
                        break;
                    case 3:
                        message.srSapAcao = reader.readBool();
                        break;
                    case 4:
                        message.srSapKit = reader.readBool();
                        break;
                    case 5:
                        message.srSapPolarizada = reader.readBool();
                        break;
                    case 6:
                        message.srSapMaterial = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CustomAttributes {
            return CustomAttributes.deserialize(bytes);
        }
    }
    export class Category extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            label?: string;
            depth?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("label" in data && data.label != undefined) {
                    this.label = data.label;
                }
                if ("depth" in data && data.depth != undefined) {
                    this.depth = data.depth;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get label() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set label(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get depth() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set depth(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            id?: string;
            label?: string;
            depth?: number;
        }): Category {
            const message = new Category({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.label != null) {
                message.label = data.label;
            }
            if (data.depth != null) {
                message.depth = data.depth;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                label?: string;
                depth?: number;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.label != null) {
                data.label = this.label;
            }
            if (this.depth != null) {
                data.depth = this.depth;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.label.length)
                writer.writeString(2, this.label);
            if (this.depth != 0)
                writer.writeInt32(3, this.depth);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Category {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Category();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.label = reader.readString();
                        break;
                    case 3:
                        message.depth = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Category {
            return Category.deserialize(bytes);
        }
    }
    export class ScalablePrice extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            originalPrice?: number;
            price?: number;
            maximumQuantity?: number;
            minimumQuantity?: number;
            tableCode?: string;
            active?: boolean;
            points?: number;
            description?: string;
            charge?: number;
            unitPrice?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("originalPrice" in data && data.originalPrice != undefined) {
                    this.originalPrice = data.originalPrice;
                }
                if ("price" in data && data.price != undefined) {
                    this.price = data.price;
                }
                if ("maximumQuantity" in data && data.maximumQuantity != undefined) {
                    this.maximumQuantity = data.maximumQuantity;
                }
                if ("minimumQuantity" in data && data.minimumQuantity != undefined) {
                    this.minimumQuantity = data.minimumQuantity;
                }
                if ("tableCode" in data && data.tableCode != undefined) {
                    this.tableCode = data.tableCode;
                }
                if ("active" in data && data.active != undefined) {
                    this.active = data.active;
                }
                if ("points" in data && data.points != undefined) {
                    this.points = data.points;
                }
                if ("description" in data && data.description != undefined) {
                    this.description = data.description;
                }
                if ("charge" in data && data.charge != undefined) {
                    this.charge = data.charge;
                }
                if ("unitPrice" in data && data.unitPrice != undefined) {
                    this.unitPrice = data.unitPrice;
                }
            }
        }
        get originalPrice() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set originalPrice(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get price() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set price(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get maximumQuantity() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set maximumQuantity(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get minimumQuantity() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set minimumQuantity(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get tableCode() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set tableCode(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get active() {
            return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
        }
        set active(value: boolean) {
            pb_1.Message.setField(this, 6, value);
        }
        get points() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set points(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get description() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set description(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get charge() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set charge(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get unitPrice() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set unitPrice(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        static fromObject(data: {
            originalPrice?: number;
            price?: number;
            maximumQuantity?: number;
            minimumQuantity?: number;
            tableCode?: string;
            active?: boolean;
            points?: number;
            description?: string;
            charge?: number;
            unitPrice?: number;
        }): ScalablePrice {
            const message = new ScalablePrice({});
            if (data.originalPrice != null) {
                message.originalPrice = data.originalPrice;
            }
            if (data.price != null) {
                message.price = data.price;
            }
            if (data.maximumQuantity != null) {
                message.maximumQuantity = data.maximumQuantity;
            }
            if (data.minimumQuantity != null) {
                message.minimumQuantity = data.minimumQuantity;
            }
            if (data.tableCode != null) {
                message.tableCode = data.tableCode;
            }
            if (data.active != null) {
                message.active = data.active;
            }
            if (data.points != null) {
                message.points = data.points;
            }
            if (data.description != null) {
                message.description = data.description;
            }
            if (data.charge != null) {
                message.charge = data.charge;
            }
            if (data.unitPrice != null) {
                message.unitPrice = data.unitPrice;
            }
            return message;
        }
        toObject() {
            const data: {
                originalPrice?: number;
                price?: number;
                maximumQuantity?: number;
                minimumQuantity?: number;
                tableCode?: string;
                active?: boolean;
                points?: number;
                description?: string;
                charge?: number;
                unitPrice?: number;
            } = {};
            if (this.originalPrice != null) {
                data.originalPrice = this.originalPrice;
            }
            if (this.price != null) {
                data.price = this.price;
            }
            if (this.maximumQuantity != null) {
                data.maximumQuantity = this.maximumQuantity;
            }
            if (this.minimumQuantity != null) {
                data.minimumQuantity = this.minimumQuantity;
            }
            if (this.tableCode != null) {
                data.tableCode = this.tableCode;
            }
            if (this.active != null) {
                data.active = this.active;
            }
            if (this.points != null) {
                data.points = this.points;
            }
            if (this.description != null) {
                data.description = this.description;
            }
            if (this.charge != null) {
                data.charge = this.charge;
            }
            if (this.unitPrice != null) {
                data.unitPrice = this.unitPrice;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.originalPrice != 0)
                writer.writeInt32(1, this.originalPrice);
            if (this.price != 0)
                writer.writeInt32(2, this.price);
            if (this.maximumQuantity != 0)
                writer.writeInt32(3, this.maximumQuantity);
            if (this.minimumQuantity != 0)
                writer.writeInt32(4, this.minimumQuantity);
            if (this.tableCode.length)
                writer.writeString(5, this.tableCode);
            if (this.active != false)
                writer.writeBool(6, this.active);
            if (this.points != 0)
                writer.writeInt32(7, this.points);
            if (this.description.length)
                writer.writeString(8, this.description);
            if (this.charge != 0)
                writer.writeInt32(9, this.charge);
            if (this.unitPrice != 0)
                writer.writeInt32(10, this.unitPrice);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ScalablePrice {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ScalablePrice();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.originalPrice = reader.readInt32();
                        break;
                    case 2:
                        message.price = reader.readInt32();
                        break;
                    case 3:
                        message.maximumQuantity = reader.readInt32();
                        break;
                    case 4:
                        message.minimumQuantity = reader.readInt32();
                        break;
                    case 5:
                        message.tableCode = reader.readString();
                        break;
                    case 6:
                        message.active = reader.readBool();
                        break;
                    case 7:
                        message.points = reader.readInt32();
                        break;
                    case 8:
                        message.description = reader.readString();
                        break;
                    case 9:
                        message.charge = reader.readInt32();
                        break;
                    case 10:
                        message.unitPrice = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ScalablePrice {
            return ScalablePrice.deserialize(bytes);
        }
    }
    export class Image extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            url?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("url" in data && data.url != undefined) {
                    this.url = data.url;
                }
            }
        }
        get url() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set url(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            url?: string;
        }): Image {
            const message = new Image({});
            if (data.url != null) {
                message.url = data.url;
            }
            return message;
        }
        toObject() {
            const data: {
                url?: string;
            } = {};
            if (this.url != null) {
                data.url = this.url;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.url.length)
                writer.writeString(1, this.url);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Image {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Image();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.url = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Image {
            return Image.deserialize(bytes);
        }
    }
    export class Brand extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: number;
            externalId?: string;
            label?: string;
            originalLabel?: string;
            categoryId?: number;
            categoryLabel?: string;
            images?: Image[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
                if ("label" in data && data.label != undefined) {
                    this.label = data.label;
                }
                if ("originalLabel" in data && data.originalLabel != undefined) {
                    this.originalLabel = data.originalLabel;
                }
                if ("categoryId" in data && data.categoryId != undefined) {
                    this.categoryId = data.categoryId;
                }
                if ("categoryLabel" in data && data.categoryLabel != undefined) {
                    this.categoryLabel = data.categoryLabel;
                }
                if ("images" in data && data.images != undefined) {
                    this.images = data.images;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get label() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set label(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get originalLabel() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set originalLabel(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get categoryId() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set categoryId(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get categoryLabel() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set categoryLabel(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get images() {
            return pb_1.Message.getRepeatedWrapperField(this, Image, 7) as Image[];
        }
        set images(value: Image[]) {
            pb_1.Message.setRepeatedWrapperField(this, 7, value);
        }
        static fromObject(data: {
            id?: number;
            externalId?: string;
            label?: string;
            originalLabel?: string;
            categoryId?: number;
            categoryLabel?: string;
            images?: ReturnType<typeof Image.prototype.toObject>[];
        }): Brand {
            const message = new Brand({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            if (data.label != null) {
                message.label = data.label;
            }
            if (data.originalLabel != null) {
                message.originalLabel = data.originalLabel;
            }
            if (data.categoryId != null) {
                message.categoryId = data.categoryId;
            }
            if (data.categoryLabel != null) {
                message.categoryLabel = data.categoryLabel;
            }
            if (data.images != null) {
                message.images = data.images.map(item => Image.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: number;
                externalId?: string;
                label?: string;
                originalLabel?: string;
                categoryId?: number;
                categoryLabel?: string;
                images?: ReturnType<typeof Image.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            if (this.label != null) {
                data.label = this.label;
            }
            if (this.originalLabel != null) {
                data.originalLabel = this.originalLabel;
            }
            if (this.categoryId != null) {
                data.categoryId = this.categoryId;
            }
            if (this.categoryLabel != null) {
                data.categoryLabel = this.categoryLabel;
            }
            if (this.images != null) {
                data.images = this.images.map((item: Image) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id != 0)
                writer.writeInt32(1, this.id);
            if (this.externalId.length)
                writer.writeString(2, this.externalId);
            if (this.label.length)
                writer.writeString(3, this.label);
            if (this.originalLabel.length)
                writer.writeString(4, this.originalLabel);
            if (this.categoryId != 0)
                writer.writeInt32(5, this.categoryId);
            if (this.categoryLabel.length)
                writer.writeString(6, this.categoryLabel);
            if (this.images.length)
                writer.writeRepeatedMessage(7, this.images, (item: Image) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Brand {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Brand();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readInt32();
                        break;
                    case 2:
                        message.externalId = reader.readString();
                        break;
                    case 3:
                        message.label = reader.readString();
                        break;
                    case 4:
                        message.originalLabel = reader.readString();
                        break;
                    case 5:
                        message.categoryId = reader.readInt32();
                        break;
                    case 6:
                        message.categoryLabel = reader.readString();
                        break;
                    case 7:
                        reader.readMessage(message.images, () => pb_1.Message.addToRepeatedWrapperField(message, 7, Image.deserialize(reader), Image));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Brand {
            return Brand.deserialize(bytes);
        }
    }
    export class Flavor extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            name?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            id?: string;
            name?: string;
        }): Flavor {
            const message = new Flavor({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                name?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Flavor {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Flavor();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Flavor {
            return Flavor.deserialize(bytes);
        }
    }
}
